import { handleActions } from 'redux-actions';
import {
  saveOfferPending,
  saveOfferSuccess,
  saveOfferFailure,
  deleteOfferPending,
  deleteOfferSuccess,
  deleteOfferFailure,
  deleteImagePending,
  deleteImageSuccess,
  deleteImageFailure,
  setFormValue,
  setStartedAt,
  setOfferImage,
  setVenue,
  setOfferType,
  resetForm,
  fillForm
} from 'gigshq/actions/offers';

const OFFER_FORM_INITIAL_STATE = {
  deleteImageMutation: {
    isPending: false,
    errors: null
  },

  form: {
    offerType: '',
    redemptionPeriod: 'daily',
    description: '',
    fineprint: '',
    image: {
      id: '',
    },
    isReversedBlackout: false,
    blackoutDates: [],
    paused: false,
    deletedAt: null,
    venue: {
      id: '',
      slug: ''
    }
  },
  isDirty: false,
  isPending: false,
  data: null,
  errors: null
};

const INITIAL_STATE = {
  offerCreation: OFFER_FORM_INITIAL_STATE,
  offerEdition: OFFER_FORM_INITIAL_STATE
};

export default handleActions(
  {
    [saveOfferPending]: (state, {payload}) => ({
      ...state,
      [payload.form]: {
        ...state[payload.form],
        isPending: true,
        data: null,
        errors: null
      }
    }),

    [saveOfferSuccess]: (state, {payload}) => ({
      ...state,
      [payload.form]: {
        ...state[payload.form],
        isDirty: false,
        isPending: false,
        data: payload.result.venueOffer,
        errors: null
      }
    }),

    [saveOfferFailure]: (state, {payload}) => ({
      ...state,
      [payload.form]: {
        ...state[payload.form],
        isPending: false,
        data: null,
        errors: payload.error
      }
    }),

    [resetForm]: (state, {payload}) => ({
      ...state,
      [payload.form]: {
        ...INITIAL_STATE[payload.form]
      }
    }),

    [setFormValue]: (state, {payload}) => ({
      ...state,
      [payload.form]: {
        ...state[payload.form],
        isDirty: true,
        form: {
          ...state[payload.form].form,
          [payload.name]: payload.value
        }
      }
    }),

    [setStartedAt]: (state, {payload}) => {
      return {
        ...state,
        [payload.form]: {
          ...state[payload.form],
          isDirty: true,
          form: {
            ...state[payload.form].form,
            startedAt: payload.startedAt
          }
        }
      };
    },

    [setVenue]: (state, {payload}) => {
      const venue = state[payload.form].form.venue;
      const newVenue = payload.venue;

      return {
        ...state,
        [payload.form]: {
          ...state[payload.form],
          isDirty: true,
          form: {
            ...state[payload.form].form,
            venue: {
              ...venue,
              ...newVenue
            }
          }
        }
      };
    },

    [setOfferType]: (state, {payload}) => {
      const newType = payload.offerType.value;

      return {
        ...state,
        [payload.form]: {
          ...state[payload.form],
          isDirty: true,
          form: {
            ...state[payload.form].form,
            offerType: newType,
          }
        }
      };
    },

    [fillForm]: (state, {payload}) => ({
      ...state,
      [payload.form]: {
        ...state[payload.form],
        form: payload.values
      }
    }),

    [setOfferImage]: (state, {payload}) => {
      const image = state[payload.form].form.image;
      const newImage = payload.image;

      return {
        ...state,
        [payload.form]: {
          ...state[payload.form],
          isDirty: true,
          form: {
            ...state[payload.form].form,
            image: {
              ...image,
              ...newImage
            }
          }
        }
      };
    },

    [deleteOfferPending]: state => ({
      ...state,
      deleteOfferMutation: {
        isPending: true,
        errors: null
      }
    }),

    [deleteOfferSuccess]: state => ({
      ...state,
      deleteOfferMutation: {
        isPending: false,
        errors: null
      }
    }),

    [deleteOfferFailure]: (state, {payload}) => ({
      ...state,
      deleteOfferMutation: {
        isPending: false,
        errors: payload
      }
    }),

    [deleteImagePending]: state => ({
      ...state,
      deleteImageMutation: {
        isPending: true,
        errors: null
      }
    }),

    [deleteImageSuccess]: state => ({
      ...state,
      deleteImageMutation: {
        isPending: false,
        errors: null
      }
    }),

    [deleteImageFailure]: (state, {payload}) => ({
      ...state,
      deleteImageMutation: {
        isPending: false,
        errors: payload
      }
    }),
  },
  INITIAL_STATE
);
