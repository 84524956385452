define("gigshq/graphql/mutations/venue-offer-persist", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "kind": "Document",
    "definitions": [{
      "kind": "OperationDefinition",
      "operation": "mutation",
      "name": {
        "kind": "Name",
        "value": "VenueOfferPersist"
      },
      "variableDefinitions": [{
        "kind": "VariableDefinition",
        "variable": {
          "kind": "Variable",
          "name": {
            "kind": "Name",
            "value": "id"
          }
        },
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        },
        "directives": []
      }, {
        "kind": "VariableDefinition",
        "variable": {
          "kind": "Variable",
          "name": {
            "kind": "Name",
            "value": "venueId"
          }
        },
        "type": {
          "kind": "NonNullType",
          "type": {
            "kind": "NamedType",
            "name": {
              "kind": "Name",
              "value": "ID"
            }
          }
        },
        "directives": []
      }, {
        "kind": "VariableDefinition",
        "variable": {
          "kind": "Variable",
          "name": {
            "kind": "Name",
            "value": "offerType"
          }
        },
        "type": {
          "kind": "NonNullType",
          "type": {
            "kind": "NamedType",
            "name": {
              "kind": "Name",
              "value": "String"
            }
          }
        },
        "directives": []
      }, {
        "kind": "VariableDefinition",
        "variable": {
          "kind": "Variable",
          "name": {
            "kind": "Name",
            "value": "redemptionPeriod"
          }
        },
        "type": {
          "kind": "NonNullType",
          "type": {
            "kind": "NamedType",
            "name": {
              "kind": "Name",
              "value": "String"
            }
          }
        },
        "directives": []
      }, {
        "kind": "VariableDefinition",
        "variable": {
          "kind": "Variable",
          "name": {
            "kind": "Name",
            "value": "description"
          }
        },
        "type": {
          "kind": "NonNullType",
          "type": {
            "kind": "NamedType",
            "name": {
              "kind": "Name",
              "value": "String"
            }
          }
        },
        "directives": []
      }, {
        "kind": "VariableDefinition",
        "variable": {
          "kind": "Variable",
          "name": {
            "kind": "Name",
            "value": "fineprint"
          }
        },
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        },
        "directives": []
      }, {
        "kind": "VariableDefinition",
        "variable": {
          "kind": "Variable",
          "name": {
            "kind": "Name",
            "value": "imageId"
          }
        },
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        },
        "directives": []
      }, {
        "kind": "VariableDefinition",
        "variable": {
          "kind": "Variable",
          "name": {
            "kind": "Name",
            "value": "isReversedBlackout"
          }
        },
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Boolean"
          }
        },
        "directives": []
      }, {
        "kind": "VariableDefinition",
        "variable": {
          "kind": "Variable",
          "name": {
            "kind": "Name",
            "value": "blackoutDates"
          }
        },
        "type": {
          "kind": "ListType",
          "type": {
            "kind": "NonNullType",
            "type": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "Date"
              }
            }
          }
        },
        "directives": []
      }, {
        "kind": "VariableDefinition",
        "variable": {
          "kind": "Variable",
          "name": {
            "kind": "Name",
            "value": "paused"
          }
        },
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Boolean"
          }
        },
        "directives": []
      }],
      "directives": [],
      "selectionSet": {
        "kind": "SelectionSet",
        "selections": [{
          "kind": "Field",
          "name": {
            "kind": "Name",
            "value": "venueOfferPersist"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "input"
            },
            "value": {
              "kind": "ObjectValue",
              "fields": [{
                "kind": "ObjectField",
                "name": {
                  "kind": "Name",
                  "value": "id"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "id"
                  }
                }
              }, {
                "kind": "ObjectField",
                "name": {
                  "kind": "Name",
                  "value": "venueId"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "venueId"
                  }
                }
              }, {
                "kind": "ObjectField",
                "name": {
                  "kind": "Name",
                  "value": "offerType"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "offerType"
                  }
                }
              }, {
                "kind": "ObjectField",
                "name": {
                  "kind": "Name",
                  "value": "redemptionPeriod"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "redemptionPeriod"
                  }
                }
              }, {
                "kind": "ObjectField",
                "name": {
                  "kind": "Name",
                  "value": "description"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "description"
                  }
                }
              }, {
                "kind": "ObjectField",
                "name": {
                  "kind": "Name",
                  "value": "fineprint"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "fineprint"
                  }
                }
              }, {
                "kind": "ObjectField",
                "name": {
                  "kind": "Name",
                  "value": "imageId"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "imageId"
                  }
                }
              }, {
                "kind": "ObjectField",
                "name": {
                  "kind": "Name",
                  "value": "isReversedBlackout"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "isReversedBlackout"
                  }
                }
              }, {
                "kind": "ObjectField",
                "name": {
                  "kind": "Name",
                  "value": "blackoutDates"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "blackoutDates"
                  }
                }
              }, {
                "kind": "ObjectField",
                "name": {
                  "kind": "Name",
                  "value": "paused"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "paused"
                  }
                }
              }]
            }
          }],
          "directives": [],
          "selectionSet": {
            "kind": "SelectionSet",
            "selections": [{
              "kind": "Field",
              "name": {
                "kind": "Name",
                "value": "venueOffer"
              },
              "arguments": [],
              "directives": [],
              "selectionSet": {
                "kind": "SelectionSet",
                "selections": [{
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "id"
                  },
                  "arguments": [],
                  "directives": []
                }, {
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "offerType"
                  },
                  "arguments": [],
                  "directives": []
                }, {
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "redemptionPeriod"
                  },
                  "arguments": [],
                  "directives": []
                }, {
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "description"
                  },
                  "arguments": [],
                  "directives": []
                }, {
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "fineprint"
                  },
                  "arguments": [],
                  "directives": []
                }, {
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "isReversedBlackout"
                  },
                  "arguments": [],
                  "directives": []
                }, {
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "blackoutDates"
                  },
                  "arguments": [],
                  "directives": []
                }, {
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "paused"
                  },
                  "arguments": [],
                  "directives": []
                }, {
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "deletedAt"
                  },
                  "arguments": [],
                  "directives": []
                }, {
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "venue"
                  },
                  "arguments": [],
                  "directives": [],
                  "selectionSet": {
                    "kind": "SelectionSet",
                    "selections": [{
                      "kind": "Field",
                      "name": {
                        "kind": "Name",
                        "value": "id"
                      },
                      "arguments": [],
                      "directives": []
                    }, {
                      "kind": "Field",
                      "name": {
                        "kind": "Name",
                        "value": "slug"
                      },
                      "arguments": [],
                      "directives": []
                    }, {
                      "kind": "Field",
                      "name": {
                        "kind": "Name",
                        "value": "maxedActiveOffers"
                      },
                      "arguments": [],
                      "directives": []
                    }]
                  }
                }]
              }
            }, {
              "kind": "Field",
              "name": {
                "kind": "Name",
                "value": "errors"
              },
              "arguments": [],
              "directives": [],
              "selectionSet": {
                "kind": "SelectionSet",
                "selections": [{
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "path"
                  },
                  "arguments": [],
                  "directives": []
                }, {
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "messages"
                  },
                  "arguments": [],
                  "directives": []
                }]
              }
            }]
          }
        }]
      }
    }],
    "loc": {
      "start": 0,
      "end": 988,
      "source": {
        "body": "\n  mutation VenueOfferPersist(\n    $id: ID\n    $venueId: ID!\n    $offerType: String!\n    $redemptionPeriod: String!\n    $description: String!\n    $fineprint: String\n    $imageId: ID\n    $isReversedBlackout: Boolean\n    $blackoutDates: [Date!]\n    $paused: Boolean\n  ) {\n    venueOfferPersist(\n      input: {\n        id: $id\n        venueId: $venueId\n        offerType: $offerType\n        redemptionPeriod: $redemptionPeriod\n        description: $description\n        fineprint: $fineprint\n        imageId: $imageId\n        isReversedBlackout: $isReversedBlackout\n        blackoutDates: $blackoutDates\n        paused: $paused\n      }\n    ) {\n      venueOffer {\n        id\n        offerType\n        redemptionPeriod\n        description\n        fineprint\n        isReversedBlackout\n        blackoutDates\n        paused\n        deletedAt\n\n        venue {\n          id\n          slug\n          maxedActiveOffers\n        }\n      }\n\n      errors {\n        path\n        messages\n      }\n    }\n  }\n",
        "name": "GraphQL request",
        "locationOffset": {
          "line": 1,
          "column": 1
        }
      }
    }
  };
  _exports.default = _default;
});
