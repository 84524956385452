import { inject as service } from '@ember/service';
import { bool, and, or, notEmpty } from '@ember/object/computed';
import Component from '@ember/component';
import { computed } from '@ember/object';
import LocationStyleable from 'gigshq/mixins/components/locationable-styleable';
import Localizable from 'ember-cli-pod-translations/mixins/localizable';
import translations from './translations';
import removeAccents from 'remove-accents';

const DOOR_PRICE_ICON = 'door';
const TICKET_PRICE_ICON = 'ticket';

export default Component.extend(Localizable(translations), LocationStyleable, {
  tagName: 'article',

  media: service(),

  hasDoorPrice: bool('event.doorPriceCents'),
  hasTicketPrice: bool('event.ticketPriceCents'),
  hasBothPrices: and('hasDoorPrice', 'hasTicketPrice'),
  hasAPrice: notEmpty('event.ticketPriceCents', 'event.ticketPriceCents'),
  isNotFree: or('event.doorPriceCents', 'event.ticketPriceCents'),
  seeSiteForTicketPrice: computed('event.ticketPriceCents', function() {
    return this.event.ticketPriceCents === -1;
  }),

  firstGigStartedAt: computed('event.gigs.[]', function() {
    if (this.event.gigs.length === 0)
      return this.event.startedAt;

    return this.event.gigs.sort((eventA, eventB) => {
      return eventA.startedAt - eventB.startedAt;
    })[0].startedAt;
  }),

  shouldShowArtistList: computed('artistList', 'event.name', function() {
    return removeAccents(this.artistList).toLowerCase() !== removeAccents(this.event.name).toLowerCase();
  }),

  artistList: computed('event.gigs.[]', function() {
    return this.event.gigs.map(gig => gig.artist.name.toUpperCase()).join(' / ');
  }),

  eventPrice: computed(
    'hasTicketPrice',
    'hasDoorPrice',
    'event.{doorPriceCents,ticketPriceCents}',
    function() {
      if (this.hasDoorPrice)
        return {
          iconName: DOOR_PRICE_ICON,
          price: this.event.doorPriceCents
        };
      return {
        iconName: TICKET_PRICE_ICON,
        price: this.event.ticketPriceCents
      };
    }
  ),

  venueLocation: computed('event.venue.{city,province}', function() {
    return [this.event.venue.city, this.event.venue.province].filter(n => n).join(', ');
  }),

  eventCoverImage: computed(
      'media.isMobile',
      'event.{shareableProfile.wideThumbnail,shareableProfile.squareThumbnail,firstEventPhoto,wideCrop.image.imageUrl,squareCrop.image.imageUrl}', function() {
    if (this.event.firstEventPhoto)
      return this.event.firstEventPhoto;

    if (this.media.isMobile && (this.event.shareableProfile.squareThumbnail != null || this.event.squareCrop?.image.imageUrl != null))
      return this.event.shareableProfile.squareThumbnail || this.event.squareCrop?.image.imageUrl;

    return this.event.shareableProfile.wideThumbnail || this.event.wideCrop?.image.imageUrl;
  })
});
