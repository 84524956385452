import { not, notEmpty } from '@ember/object/computed';
import Component from '@ember/component';
import { computed } from '@ember/object';
import ErrorFormatter from 'gigshq/utils/error-formatter';
import Localizable from 'ember-cli-pod-translations/mixins/localizable';
import translations from './translations';
import offerTypes from 'gigshq/constants/offer-types';

export default Component.extend(Localizable(translations), {
  tagName: 'form',

  characterCountLimit: 140,

  offerTypes: offerTypes,
  redemptionPeriodOptions: [ 'daily', 'weekly' ],

  initialPausedValue: null,

  didReceiveAttrs() {
    if (this.initialPausedValue == null)
      this.initialPausedValue = this.paused;
  },

  hasVenue: notEmpty('venue.id'),

  selectedOfferType: computed('offerType', function() {
    return this.offerTypes.find(offerType => offerType.value === this.offerType);
  }),

  offerActive: not('paused'),

  offerActiveIcon: computed('paused', function() {
    return this.paused ? 'pause-circle' : 'check-circle';
  }),

  offerActiveColour: computed('paused', function() {
    return this.paused ? 'bg-red' : 'bg-green';
  }),

  offerActiveLegend: computed('paused', function() {
    return this.paused ? 'status.paused' : 'status.active';
  }),

  showMaxedActiveOfferNote: computed('initialPausedValue', 'venue.maxedActiveOffers', function() {
    return this.venue.maxedActiveOffers && this.initialPausedValue;
  }),

  actions: {
    toggleOffer(checked) {
      this.setProperty('paused', !checked);
    },

    didCreateImage(image) {
      this.setOfferImage(image);
      this.setProperty('image', {
        id: image.id,
        imageUrl: image.thumbnailImageUrl
      });
    },
  },

  baseErrors: computed('errors.[]', function() {
    return ErrorFormatter.byPath('base', this.errors);
  }),

  errorsByInputName: computed('errors.[]', function() {
    return ErrorFormatter.byInputName(this.errors);
  }),
});
