import Component from '@ember/component';
import { computed } from '@ember/object';
import { notEmpty } from '@ember/object/computed';
import Localizable from 'ember-cli-pod-translations/mixins/localizable';
import translations from './translations';

export default Component.extend(Localizable(translations), {
  tagName: 'li',

  upcomingBlackoutDates: computed('offer.blackoutDates', function() {
    return this.offer.blackoutDates && this.offer.blackoutDates
      .map((date) => moment(date))
      .sort((a, b) => a - b)
      .filter((date) => date.isSameOrAfter(moment()));
  }),

  hasUpcomingBlackoutDates: notEmpty('upcomingBlackoutDates'),

  shouldShowItem: computed('offer.isReversedBlackout', 'hasUpcomingBlackoutDates', function() {
    if (this.offer.isReversedBlackout && this.hasUpcomingBlackoutDates)
      return true;

    if (!this.offer.isReversedBlackout)
      return true;

    return false;
  }),

  blackoutDatesDisplay: computed('upcomingBlackoutDates', function() {
    return this.upcomingBlackoutDates
      .map((date) => date.format('L'))
      .join(', ');
  }),
});
