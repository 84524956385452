import { inject as service } from '@ember/service';
import ReduxContainer from 'gigshq/pods/components/gigs-redux-container/component';
import { PropTypes as T } from 'ember-prop-types';
import { connect } from 'ember-redux';
import moment from 'moment';

// Actions
import {
  saveOffer,
  deleteOffer,
  setFormValue,
  setVenue,
  setOfferImage,
  setOfferType,
  resetForm,
  deleteImage
} from 'gigshq/actions/offers';

const mapStateToComputed = state => {
  const {
    offers: {
      offerEdition: {
        isDirty,
        isPending,
        data,
        errors,
        form: {
          id,
          venue,
          offerType,
          redemptionPeriod,
          description,
          fineprint,
          isReversedBlackout,
          blackoutDates,
          image,
          paused,
          deletedAt
        }
      }
    }
  } = state;

  return {
    isDirty,
    isPending,
    data,
    errors,
    id,
    venue,
    offerType,
    redemptionPeriod,
    description,
    fineprint,
    isReversedBlackout,
    blackoutDates: blackoutDates ? blackoutDates.map((date) => moment(date)) : [],
    image,
    paused,
    deletedAt: moment(deletedAt)
  };
};

const mapDispatchToActions = dispatch => {
  return {
    saveOffer: (mutate, offer) =>
      dispatch(saveOffer({form: 'offerEdition', mutate, offer})),
    deleteOffer: (mutate, offerId) => dispatch(deleteOffer({mutate, offerId})),
    resetForm: () => dispatch(resetForm({form: 'offerEdition'})),
    setVenue: venue => dispatch(setVenue({form: 'offerEdition', venue})),
    setOfferImage: image => dispatch(setOfferImage({form: 'offerEdition', image})),
    setOfferType: offerType => dispatch(setOfferType({form: 'offerEdition', offerType})),
    setFormValue: (name, value) =>
      dispatch(setFormValue({form: 'offerEdition', name, value})),
    deleteImage: (mutate, imageId) => dispatch(deleteImage({mutate, imageId}))
  };
};

const OfferCreateForm = ReduxContainer.extend({
  accountManager: service('managers/account-manager'),
  apollo: service(),
  router: service(),

  propTypes: {
    offerId: T.string,
    venues: T.array,
    onOfferCreate: T.func,
    onOfferSave: T.func
  },

  willDestroyElement() {
    this.get('actions.resetForm')();
  },

  actions: {
    setVenue(venue) {
      this.get('actions.setVenue')(venue);
    },

    setOfferImage(image) {
      this.get('actions.setOfferImage')(image);
    },

    setOfferType(offerType) {
      this.get('actions.setOfferType')(offerType.value);
    },

    save() {
      const properties = this.getProperties(
        'id',
        'offerType',
        'redemptionPeriod',
        'description',
        'fineprint',
        'isReversedBlackout',
        'blackoutDates',
        'paused'
      );

      const variables = {
        ...properties,
        venueId: this.get('venue.id'),
        imageId: this.get('image.id'),
      };

      this.get('actions.saveOffer')(
        this.apollo.mutationOperation(),
        variables
      ).then(result => {
        const id = this.get('data.id');
        if (!id) return;

        if (this.onOfferSave)
          this.onOfferSave();

        if (this.onOfferCreate)
          this.onOfferCreate();
      });
    },

    delete(offerId) {
      return this.get('actions.deleteOffer')(
        this.apollo.mutationOperation(),
        offerId
      ).then(() => {
        this.router.transitionTo('app.offers.index');
      });
    },

    deleteOfferImage(imageId) {
      return this.get('actions.deleteImage')(
        this.apollo.mutationOperation(),
        imageId
      ).then(() => {
        this.get('actions.setFormValue')('image', null);
      });
    }
  }
});

export default connect(
  mapStateToComputed,
  mapDispatchToActions
)(OfferCreateForm);
