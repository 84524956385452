import Service, { inject as service } from '@ember/service';
import { getDistance } from 'geolib';
import config from 'gigshq/config/environment';

// Queries
import venuesQuery from 'gigshq/graphql/queries/web-venues';
import managedVenuesQuery from 'gigshq/graphql/queries/managed-venues';

export default Service.extend({
  apollo: service(),
  accountManager: service('managers/account-manager'),
  favouriteVenuesManager: service('managers/favourite-venues-manager'),
  eventsFetcher: service('fetchers/events-fetcher'),
  sessionFetcher: service('fetchers/session-fetcher'),
  sessionStorage: service('session-storage'),

  searchedVenues: [],
  fetchedVenues: [],
  venuesMapBounds: null,
  isLoadingVenues: false,
  lastFetchedCoordinates: null,

  searchVenues(searchQuery) {
    const variables = {
      filters: [{
        scope: 'name_search',
        arguments: searchQuery,
      }]
    };
    return this._makeVenuesFetchCall(variables, true);
  },

  clearSearchVenues() {
    this.set('searchedVenues', null);
  },

  fetchVenues(venueIds) {
    const variables = {
      filters: [{
        scope: 'venue_ids',
        arguments: [ venueIds ]
      }]
    };
    this.set('venuesMapBounds', null);
    return this._makeVenuesFetchCall(variables);
  },

  fetchVenuesInBounds(mapBounds) {
    if (mapBounds == null) {
      // if map bounds is null, it's because they zoomed out too much
      this.set('fetchedVenues', []);
      return this.get('fetchedVenues');
    }

    const northEastBound = {
      latitude: mapBounds.getNorthEast().lat(),
      longitude: mapBounds.getNorthEast().lng()
    };

    const southWestBound = {
      latitude: mapBounds.getSouthWest().lat(),
      longitude: mapBounds.getSouthWest().lng()
    };

    if (this.venuesMapBounds &&
       this.venuesMapBounds.contains(mapBounds.getNorthEast()) &&
       this.venuesMapBounds.contains(mapBounds.getSouthWest())) {
         return this.fetchedVenues;
    }

    const circleRadius = getDistance(
      { latitude: northEastBound.latitude, longitude: northEastBound.longitude },
      { latitude: southWestBound.latitude, longitude: southWestBound.longitude }) / 1000.0;
    const variables = {
      filters: [{
        scope: 'within',
        arguments: [
          circleRadius * config.MapConfiguration.GOOGLE_MAPS_GIGSHQ_EVENT_FETCH_RADIUS_MULTIPLIER,
          { origin: [ mapBounds.getCenter().lat(), mapBounds.getCenter().lng() ] }
        ]
      }]
    };

    this.set('venuesMapBounds', mapBounds);
    return this._makeVenuesFetchCall(variables, false, [ mapBounds.getCenter().lat(), mapBounds.getCenter().lng() ]);
  },

  searchManagedVenues(searchQuery, isVenueListing = false) {
    const query = managedVenuesQuery;
    const variables = { searchQuery, isVenueListing };
    return this.apollo.queryOperation()({ query, variables }, 'viewer');
  },

  async _makeVenuesFetchCall(variables, searchOnly, coordinates) {
    if (coordinates == null || this.get('lastFetchedCoordinates') != coordinates.toString()) {
      this.set('isLoadingVenues', true);

      const query = venuesQuery(this.sessionFetcher.determineUserRole());

      variables.favouriteVenues = this.favouriteVenuesManager.favouriteVenueIds.map(venueId => parseInt(venueId, 10));

      const viewer = await this.apollo.queryOperation()({ query, variables }, 'viewer');

      this.accountManager.setAccount(viewer);
      this.set('isLoadingVenues', false);
      this.set('lastFetchedCoordinates', coordinates.toString());

      // don't set the fetchedVenues if we're only conducting searches

      if (searchOnly)
        this.set('searchedVenues', viewer.publicVenues);
      else {
        this.set('fetchedVenues', viewer.publicVenues);

        viewer.publicVenues
          .forEach((venue) => this.eventsFetcher.addUpcomingEvents(venue.upcomingEvents));
      }
    }

    return this.get(searchOnly ? 'searchedVenues' : 'fetchedVenues');
  }
});

